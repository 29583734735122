.sv-body {
  width: 100%;
}

.sv-body__timer,
.sv-body__page,
.sv-body__footer.sv-footer.sv-action-bar {
  margin-top: 2em;

  @media only screen and (min-width: 1000px) {
    margin-right: 5%;
    margin-left: 5%;
  }

  @media only screen and (max-width: 1000px) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.sv-body__timer {
  padding: 0 calcSize(1);
  box-sizing: border-box;
}

.sv-body__progress {
  margin-bottom: 4.5em;
}

.sv-body__progress:not(:first-child) {
  margin-top: 2.5em;
}

.sv-root-modern {
  width: 100%;
}