.Footer{
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: center;
background-color: #4f4f4f;
}
.FooterWrapper{
    width: 100%;
    max-width: 1100px;
}
.FooterLinks{
display: flex;
gap: 25px;
}
.FooterLinks a{
    font-size: 13px;
    color:white;
    text-decoration: none;
}
.FooterLinks a:hover{
text-decoration: underline;
}
.FooterLine{
    width: 100%;
margin: 40px 0px 40px 0px;
    border: 1px solid #ffff;
    height: 0;
    opacity: 80%;
}
.BottomFooter{
    font-size: 13px;
    color: #ffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.BottomLeft{
flex:1,
}
.BottomRight{
    line-height: 24px;
    display: flex;
max-width: 520px;
text-align: end;
}
@media only screen and (max-width: 860px) {
    .FooterLinks{
    flex-direction: column;
        }
        .BottomFooter{
            gap: 30px;
       flex-direction: column;
        }
        .BottomRight{
        text-align: start;
        }
}