.svc-carry-forward-panel {
  box-sizing: border-box;
  width: 100%;
  padding: calcSize(4) calcSize(8);
  margin-top: calcSize(2);
  border-radius: calcSize(1);
  background-color: #F8F8F8;
  text-align: center;
  font-weight: 400;
  font-size: calcSize(2);
  line-height: calcSize(3);
  color: rgba(0, 0, 0, 0.45);
}
.svc-carry-forward-panel__link {
  .svc-action-button {
    padding: 0;
    border: none;
    font-weight: inherit;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}