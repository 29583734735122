@import "../variables.scss";

.svc-image-question-controls {
  position: absolute;
  top: calcSize(5);
  right: calcSize(1);
  display: none;
}

.svc-question__content--selected .svc-image-question-controls {
  display: flex;
}

.svc-question__content--image:not(.svc-question__content--empty) {
  padding: calcSize(4) 0 calcSize(8) 0;
}

.svc-question__content--image.svc-question__content--empty .sd-file,
.svc-question__content--image.svc-question__content--empty sv-ng-file-question {
  width: 100%;
}
.svc-question__content--image.svc-question__content--empty .sd-question--image {
  display: none;
}
