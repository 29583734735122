@import "../../variables.scss";

svc-page-navigator,
.svc-page-navigator {
  display: flex;
  flex-direction: column;
}

.svc-page-navigator__navigator-icon {
  display: block;
  height: calcSize(3);
  width: calcSize(3);
  padding: calcSize(1.25);
  border-radius: 50%;
  cursor: pointer;

  use {
    fill: $foreground-light;
  }
}

.svc-page-navigator__selector {
  width: calcSize(5.5);
  height: calcSize(5.5);
  min-height: calcSize(5.5);
}

survey-creator,
.svc-creator {
  .svc-page-navigator__selector {

    &:hover,
    &:focus {
      outline: none;

      .svc-page-navigator__navigator-icon {
        background-color: $primary-light;

        use {
          fill: $primary;
        }
      }
    }
  }
}

.svc-page-navigator__selector--opened {
  opacity: 0.5;

  .svc-page-navigator__navigator-icon {
    background-color: $primary-light;

    use {
      fill: $primary;
    }
  }
}

.svc-page-navigator__popup {
  min-width: calcSize(12.5);
}

.svc-tab-designer--with-page-navigator {
  .svc-tab-designer_content {
    margin-right: calcSize(6.5);
  }
}

.svc-tab-designer__page-navigator {
  width: calcSize(5.5);
  position: absolute;
  padding: calcSize(16.5) 0;
  top: 0;
  bottom: 0;
  right: calcSize(1);
}

.svc-creator__toolbox--right,
[dir='rtl'],
[style*='direction:rtl'],
[style*='direction: rtl'] {
  .svc-tab-designer--with-page-navigator {
    .svc-tab-designer_content {
      margin-right: 0;
      margin-left: calcSize(6.5);
    }
  }

  .svc-tab-designer__page-navigator {
    right: unset;
    left: calcSize(1);
  }
}