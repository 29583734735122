@import "../../variables.scss";

svc-tab-test {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-test-tab__content .svc-plugin-tab__content {
  overflow-y: overlay;

  .sv-root-modern .sv-completedpage,
  .sv_default_css .sv_completed_page {
    margin: 0;
    border: 0;
    background-color: $background-dim;
  }

  .sv_default_css .sv_body {
    border: 0;
  }

  .svc-preview__test-again {
    width: calcSize(33);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 72px;
    margin-top: 8px;
  }
}

.svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {
  height: calc(100% - calc(6 * #{$base-unit}));
}

.svc-test-tab__content-actions {
  position: relative;
  .sv-action-bar {
    padding: 0;
    height: calc(6 * #{$base-unit});
    background: $background;
    border-top: 1px solid $border;
    width: 100%;
    position: absolute;
  }

  .sv-action-bar.sv-action-bar--pages {
    left: 0;
    right: 0;
    justify-content: center;
    gap: calcSize(1);

    .sv-action__content {
      padding: 0;
    }
    .sv-action-bar-item {
      margin: 0;
    }
  }

  .sv-action-bar-item {
    @include smallBold;
    width: 100%;
    height: calcSize(4);
  }

  .svc-page-selector {
    max-width: 50%;

    .sv-action-bar-item__title {
      @include textEllipsis;

      color: $foreground;
      display: inline-block;
    }
  }
}

.svc-test-tab__content .sd-body--empty {
  @include centerText;
}