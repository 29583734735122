.PrivacyPolicy{
    display: flex;
    flex-direction: column;
}
.PrivacyContainer{
    background-color: #faf8f6;
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 120px 15% 10% 15%;
}
.PrivacyContainer li{
 font-size: 18px;
 font-weight: 700;
}
.contactDivPrivacy{
    gap: 3px;
    display: flex;
    flex-direction: column;
}
.contactDivPrivacy a{
    text-decoration: none;
    color: black;
    font-weight: 600;
  display: block;
}
.contactDivPrivacy a:hover{
    text-decoration: underline;
    font-weight: 650;
}
@media only screen and (max-width: 990px) {
    .PrivacyContainer{
        padding: 110px 8% 10% 8%;
    }
}